<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>线索留资</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button @click='showEditModal()' type="primary" icon="plus" >新增留资</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                                <a-select-option :value="0">我的</a-select-option>
                                <a-select-option :value="1">我下属的</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-input v-model='searchParams.search.online_name'  style="width: 160px" allowClear placeholder="留资名称"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="searchList" :loading="loading" html-type="submit" type="primary">搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="middle" :pagination="false" :bordered='false' rowKey="online_id"
                    :columns="columns" :dataSource="list" @change="handleChange">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                    <template slot="online_collect_count" slot-scope="text, record">
                        <a href="javascript:;" @click="showDetailModal(record)">{{text}}</a>
                    </template>
                    <template slot="action" slot-scope="text,record">
                        <a href="javascript:;" @click="showEditModal(record)">编辑</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" @click="showCopyModal(record)">复制链接</a>
                    </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <editModal v-if="isEditModal" :item="modalData" />
        <detailModal v-if="isDetailModal" :item="modalData" />
        <copyModal v-if="isCopyModal" :item="modalData"/>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index',fixed: 'left'},
  {title: '留资名称', dataIndex: 'online_name',key: 'online_name'},
  {title: '留资收集', dataIndex: 'online_collect_count', key: 'online_collect_count',align:'center',scopedSlots: { customRender: 'online_collect_count' },sorter:()=>{}},
  {title: '留资渠道', dataIndex: 'related_channel_name', key: 'related_channel_name'},
  {title: '留资负责人', dataIndex: 'related_to_employee_name', key: 'related_to_employee_name'},
  {title: '创建人', dataIndex: 'created_by', key: 'created_by'},
  {title: '创建时间', dataIndex: 'created_at', key: 'created_at',sorter:()=>{}},
  { title: '操作', key: 'operation',fixed:'right', width:120,align:"center",scopedSlots: { customRender: 'action' }}
]
    import editModal from './editModal'
    import detailModal from './detailModal'
    import tableMixins from '@/common/mixins/table'
    import copyModal from '@/views/market/weipages/zxbb/copyModal'
    export default {
        name:'collect',
        data() {
            return {
                columns,
                list: [],
                loading:false,
                isCopyModal:false,
                isEditModal:false,
                isDetailModal:false,
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        mixins: [ tableMixins ],
        components: {
            editModal,
            copyModal,
            detailModal
        },
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        methods: {
            async getList() {
                this.loading = true
                this.searchParams.search.is_sub = this.$ls.get('is_sub')
                await this.$store.dispatch('collectIndexAction',this.searchParams)
                .then(res=>{
                    this.list = res.items
                    this.pageParams = res._meta
                    this.loading = false
                })
            },
            showEditModal(item){
                this.modalData = item
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                }
                this.isEditModal = false
            },
            showCopyModal(item){
                this.modalData = item
                this.isCopyModal = true
            },
            hideCopyModal(){
                this.isCopyModal = false
            },
            showDetailModal(item){
                this.modalData = item
                this.isDetailModal = true
            },
            hideDetailModal(){
                this.isDetailModal = false
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
                this.$forceUpdate()
            },
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                    if(sorter.order === "ascend"){
                        this.searchParams['sort'] = `${sorter.field}`
                    }else{
                        this.searchParams['sort'] = `-${sorter.field}`
                    }
                }else{
                    this.searchParams['sort'] = ''
                    }
                this.getList()
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>