<template>
    <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
        cancelText="取消" okText="确认" width="600px" 
        @ok="handleOk" @cancel="handleCancel">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-form :form="form" layout="vertical">
                <a-form-item v-for="(item,index) in formData" :key="index" :label="item.label">
                    <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                    <a-select v-else-if="item.type === 'select'" placeholder="请选择" :dropdownStyle='{zIndex:4200}'
                    v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
                    <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                    </a-select>
                    <a-cascader
                    v-if="item.type === 'cascader'"
                    :options="seleteItems[item.items.data]"
                    v-decorator="[item.name, { rules: item.rules }]"
                    :show-search="{ filterOption }"
                    placeholder="请选择"
                    :popupStyle='{zIndex:4200}'
                    @change="onChangeCascader">
                        <template slot="displayRender" slot-scope="{labels}">
                            <span>{{labels[1]}}</span>
                        </template>
                    </a-cascader>
                    <l-editor v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'textarea'" name="recruit_description" :data="recruit_description"/>
                </a-form-item>
            </a-form>
    </a-modal>
</template>

<script>
const formData = [
    {
        name: 'online_name',
        label: '留资名称',
        type: 'input',
        rules: [{ required: true, message: '请输入留资名称!' }]
    },
    {
        name: 'related_channel_id',
        label: '来源渠道',
        type: 'cascader',
        rules: [{ required: true, message: '请选择来源渠道!' }],
        items: {
        data: 'channels',
        label: 'filter_name',
        value: 'source_channel_id'
        }
    },
    {
        name: 'related_to_employee',
        label: '负责人',
        type: 'select',
        rules: [{ required: true, message: '请选择负责人!' }],
        items: {
        data: 'consultants',
        label: 'filter_name',
        value: 'course_consultant_id'
        }
    },
    {
        name: 'online_description',
        label: '描述',
        type: 'textarea',
        rules: [],
    }

]
    export default {
        name:'editModal',
        inject: ['parent'],
        props:{
            item:Object
        },
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                formData,
                modalTitle: '',
                loading:false,
                visible:false,
                confirmLoading:false,
                channelsId:[],
                form: this.$form.createForm(this),
                seleteItems: {
                    channels:[],
                    consultants:[],
                },
                recruit_description:''
            }
        },
        async created () {
            this.visible = true
            this.modalTitle = '新建留资'
            if(this.item){
                this.modalTitle = '编辑留资'
                this.loading = true
            }
            await this.getConsultant()
            if(this.item){
                await this.$nextTick()
                let item = await this.getDetail()
                this.form.setFieldsValue({
                    online_name:item.online_name,
                    related_to_employee:item.related_to_employee,
                    online_description:item.online_description,
                })
                this.recruit_description = item.online_description
                this.getSourceChannel(item.related_channel_id)
            }else{
                this.getSourceChannel()
            }
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getDetail(){
                let res = await this.$store.dispatch('collectDetailAction', {online_id:this.item.online_id})
                return res.data
            },
            async getConsultant() {
                let res = await this.$store.dispatch('searchConsultantAction', this.item?{}:{data: {active:true}})
                this.seleteItems.consultants = res.data
            },
            async getSourceChannel(id) {
                let res = await this.$store.dispatch('searchSourceChannelCascaderAction', { })
                this.seleteItems.channels = res.data[0].children
                if(id){
                    res.data[0].children.forEach(item=>{
                        item.children.forEach(it=>{
                            if(it.value == id){
                            this.channelsId = [item.value,it.value]
                            this.form.setFieldsValue({related_channel_id:this.channelsId})
                            }
                        })
                    })
                    this.loading = false
                }
            },
            async handleOk() {
                const { item, form } = this
                let params = await form.validateFields()
                params.related_channel_id = this.channelsId[1]
                if(item){
                    params.online_id = item.online_id
                }
                this.confirmLoading = true
                await this.$store.dispatch(item ? 'collectUpdateAction' : 'collectAddAction', params)
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.confirmLoading = false
                    this.parent.hideEditModal(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败！！')
                    this.confirmLoading = false
                })
            },
            handleCancel(){
                this.parent.hideEditModal()
            },
            changeEditor(key, value) {
                this.recruit_description = value
                this.form.setFieldsValue({online_description:value})
            },
            onChangeCascader(value, selectedOptions) {
                this.channelsId = value
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>