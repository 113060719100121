<template>
    <a-modal title="留资详情" v-model="visible" :confirmLoading="confirmLoading"
        cancelText="取消" okText="确认" width="65%" :footer="null"
        @ok="handleOk" @cancel="handleCancel">
            <div class="clearfix table-tools">
                <div class="buttons"></div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                        <a-form-item>
                            <a-input v-model='searchParams.search.keyword'  style="width: 160px" allowClear placeholder="线索名称/手机号"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="searchList" html-type="submit" type="primary">搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="online_id"
                    :columns="columns" :dataSource="list">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
    </a-modal>
</template>

<script>
const columns = [
  { title: '#', scopedSlots: { customRender: 'index' }, key: 'task_id',fixed: 'left', show_colspan: true},
  {title: '线索名称', dataIndex: 'cule_name', key: 'cule_name' , scopedSlots: { customRender: 'name' }, show_colspan: true},
  {title: '联系电话', dataIndex: 'cellphone', key: 'cellphone' , scopedSlots: { customRender: 'cellphone' }, show_colspan: true},
  {title: '来源渠道', dataIndex: 'source_channel',ellipsis: true, scopedSlots: { customRender: 'source_channel' }, key: 'client_channel.level',show_sorter: true, show_colspan: true},
  {title: '归属校区', dataIndex: 'studio_id',ellipsis: true,  key: 'studio_id' ,show_sorter: true, show_colspan: true},
  {title: '负责人', dataIndex: 'distribute_to', key: '13' ,ellipsis: true,show_sorter: true, show_colspan: true},
  {title: '添加时间', dataIndex: 'created_at', key: 'created_at' ,show_sorter: true , show_colspan: true},
]
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'detailModal',
        inject: ['parent'],
        data() {
            return {
                list: [],
                visible:false,
                confirmLoading:false,
                columns
            }
        },
        props:{
            item:Object
        },
        mixins: [ tableMixins ],
        created () {
            this.visible = true
        },
        methods: {
            async getList() {
                this.loading = true
                this.searchParams.search.online_id = this.item.online_id
                await this.$store.dispatch('collectLeadsAction',this.searchParams)
                .then(res=>{
                    this.list = res.items
                    this.pageParams = res._meta
                    this.loading = false
                })
            },
            handleOk(){},
            handleCancel(){
                this.parent.hideDetailModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>